/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "typeface-roboto";
import "./src/styles/app.css";

import AOS from "aos";

AOS.init({
  offset: 20
});
