// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aanbod-detail-js": () => import("./../../../src/pages/aanbod-detail.js" /* webpackChunkName: "component---src-pages-aanbod-detail-js" */),
  "component---src-pages-aanbod-js": () => import("./../../../src/pages/aanbod.js" /* webpackChunkName: "component---src-pages-aanbod-js" */),
  "component---src-pages-bedankt-js": () => import("./../../../src/pages/bedankt.js" /* webpackChunkName: "component---src-pages-bedankt-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-team-detail-js": () => import("./../../../src/pages/team-detail.js" /* webpackChunkName: "component---src-pages-team-detail-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-vorming-js": () => import("./../../../src/pages/vorming.js" /* webpackChunkName: "component---src-pages-vorming-js" */),
  "component---src-pages-werkwijze-js": () => import("./../../../src/pages/werkwijze.js" /* webpackChunkName: "component---src-pages-werkwijze-js" */)
}

