module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#fff","description":"Website van praktijkhuis Integraal VZW.","display":"minimal-ui","icon":"src/images/integraal-icon.png","lang":"nl-BE","name":"Website Integraal VZW","short_name":"Integraal","start_url":"/","theme_color":"#3c6e8d","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ac43734a4a6013d9092d37202a5981db"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"anonymize":true,"respectDNT":true,"trackingId":"UA-150452662-1","head":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
